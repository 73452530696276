@keyframes scrollText {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

.scroller {
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
}

.scroller:hover {
  animation: scrollText 8s linear infinite;
}
